import request from './request'

// 获取服务站信息
export const getstoreList = (data) => {
  return request.post('/rame/gsfms/getStoresMenu', data)
}
// 获取派单员列表
export const getPDYMenuList = () => {
  return request.get('/rame/gsfms/getPDYMenu')
}
// 获取移动货架列表
export const getstocksMenuLiat = (data) => {
  return request.post('/rame/gsfms/stocks/getYDHJMenu', data)
}
