import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-da7ef230"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "global-body"
};
import inventory from './inventory.vue';
import { ref } from 'vue';
export default {
  __name: 'index',

  setup(__props) {
    const inventoryRef = ref();
    const activeName = ref('库存列表');

    const tabClick = e => {
      console.log(' tab切换', e.props.label);

      if (e.props.label === '库存列表') {} else if (e.props.label === '操作日志') {}
    };

    return (_ctx, _cache) => {
      const _component_el_tab_pane = _resolveComponent("el-tab-pane");

      const _component_el_tabs = _resolveComponent("el-tabs");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_tabs, {
        modelValue: activeName.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => activeName.value = $event),
        class: "demo-tabs",
        onTabClick: tabClick
      }, {
        default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
          label: "库存列表",
          name: "库存列表"
        }), _createVNode(_component_el_tab_pane, {
          label: "操作日志",
          name: "操作日志"
        })]),
        _: 1
      }, 8, ["modelValue"]), activeName.value === '库存列表' ? (_openBlock(), _createBlock(inventory, {
        key: 0,
        ref_key: "inventoryRef",
        ref: inventoryRef
      }, null, 512)) : _createCommentVNode("", true)]);
    };
  }

};