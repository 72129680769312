import request from './request'

// 获取师傅列表
export const getmasterList = (data) => {
  return request.post('/rame/gsfms/getSF', data)
}
// 上传师傅
export const addmaster = (data) => {
  return request.post('/rame/gsfms/addSF', data)
}
// 编辑师傅
export const editmaster = (data) => {
  return request.post('/rame/gsfms/updateSF', data)
}
// 获取师傅
export const getWorkersList = (data) => {
  return request.post('/rame/business/getWorkers', data)
}
//  开始派单-上传师傅
export const addOrderSF = (data) => {
  return request.post('/rame/business/addOrderSF', data)
}

export const getAfterSalesList = (data) => {
  return request.post('/jf-tech/v1/getOrdersAfterSales', data)
}

export const editAfterSales = (data) => {
  return request.post('/jf-tech/v1/updateSaleInfo', data)
}

export const addGoods = (data) => {
  return request.post('/jf-tech/v1/addGoods', data)
}

export const delAfterSales = (data) => {
  return request.post('/jf-tech/v1/deleteGoods', data)
}

export const get3DBallData = (data) => {
  return request.get('/get3DBallData', data)
}

export const delImgItem = (data) => {
  return request.post('/jf-tech/v1/deleteGoodsImages', data)
}
