import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-4c4706fc"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "item_left"
};
const _hoisted_2 = {
  class: "item_title"
};
const _hoisted_3 = {
  class: "item_title"
};
const _hoisted_4 = {
  class: "itemcol"
};
const _hoisted_5 = {
  class: "listtitle2"
};
import baseEchart from '../../../components/baseEchart/index.vue';
/**
 * title: '商品',
  value: '897768',
  color: '#5B8FF9',
  ishowEchart: true,是否显示echart
 *  */

export default {
  __name: 'item',
  props: {
    incomeData: {} // 接收option的内容 完善中

  },

  setup(__props) {
    var _props$incomeData, _props$incomeData2;

    // 在访问prop的属性之前，可以使用可选链（Optional Chaining）操作符?.来避免错误，例如：{{ myProp?.title }}。
    const props = __props;
    const options = {
      tooltip: {
        trigger: 'item'
      },
      series: [{
        name: (_props$incomeData = props.incomeData) === null || _props$incomeData === void 0 ? void 0 : _props$incomeData.title,
        type: 'pie',
        radius: ['35%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: true,
          position: 'inside',
          formatter: '{d}%'
        },
        labelLine: {
          show: false
        },
        data: (_props$incomeData2 = props.incomeData) === null || _props$incomeData2 === void 0 ? void 0 : _props$incomeData2.data
      }]
    };
    return (_ctx, _cache) => {
      const _component_el_col = _resolveComponent("el-col");

      const _component_el_image = _resolveComponent("el-image");

      const _component_el_row = _resolveComponent("el-row");

      return _openBlock(), _createBlock(_component_el_row, {
        gutter: 20,
        style: {
          "align-items": "center"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 4
        }, {
          default: _withCtx(() => {
            var _props$incomeData3, _props$incomeData4, _props$incomeData5, _props$incomeData6, _props$incomeData7;

            return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString((_props$incomeData3 = __props.incomeData) === null || _props$incomeData3 === void 0 ? void 0 : _props$incomeData3.title), 1), _createElementVNode("div", _hoisted_3, _toDisplayString((_props$incomeData4 = __props.incomeData) === null || _props$incomeData4 === void 0 ? void 0 : _props$incomeData4.title2), 1), _createElementVNode("div", {
              class: "item_value",
              style: _normalizeStyle({
                color: (_props$incomeData5 = __props.incomeData) !== null && _props$incomeData5 !== void 0 && _props$incomeData5.color ? (_props$incomeData6 = __props.incomeData) === null || _props$incomeData6 === void 0 ? void 0 : _props$incomeData6.color : '#000'
              })
            }, _toDisplayString((_props$incomeData7 = __props.incomeData) === null || _props$incomeData7 === void 0 ? void 0 : _props$incomeData7.value), 5)])];
          }),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 16
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, {
            gutter: 60
          }, {
            default: _withCtx(() => {
              var _props$incomeData8;

              return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_props$incomeData8 = __props.incomeData) === null || _props$incomeData8 === void 0 ? void 0 : _props$incomeData8.list, (item, index) => {
                return _openBlock(), _createBlock(_component_el_col, {
                  span: 4,
                  key: index
                }, {
                  default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [item.imageUrl ? (_openBlock(), _createBlock(_component_el_image, {
                    key: 0,
                    class: "title-image",
                    src: item.imageUrl
                  }, null, 8, ["src"])) : _createCommentVNode("", true), _createElementVNode("div", {
                    class: _normalizeClass(item.listtitle2 ? 'listtitle' : 'listtitle nonetitle2')
                  }, _toDisplayString(item.listtitle), 3), _createElementVNode("div", _hoisted_5, _toDisplayString(item.listtitle2), 1), _createElementVNode("div", {
                    class: "listvalue",
                    style: _normalizeStyle({
                      color: item.listcolor
                    })
                  }, _toDisplayString(item.listvalue), 5)])]),
                  _: 2
                }, 1024);
              }), 128))];
            }),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 4
        }, {
          default: _withCtx(() => {
            var _props$incomeData9;

            return [(_props$incomeData9 = __props.incomeData) !== null && _props$incomeData9 !== void 0 && _props$incomeData9.ishowEchart ? (_openBlock(), _createBlock(baseEchart, {
              key: 0,
              option: options,
              width: "500px",
              height: "200px"
            })) : _createCommentVNode("", true)];
          }),
          _: 1
        })]),
        _: 1
      });
    };
  }

};