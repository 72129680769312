import request from './request'
// 6.5 【库存管理】
// 获取6.5.1 获取商品菜单
export const getShopMenuList = () => {
  return request.get('/rame/gsfms/stocks/getGoodsMenu')
}
// 6.5.2 获取商品 规格型号菜单
export const getGoodsSortsMenu = (data) => {
  return request.post('/rame/gsfms/stocks/getGoodsSortsMenu', data)
}
// 6.5.3 获取商品信息
export const getGoodsInfo = (data) => {
  return request.post('/rame/gsfms/stocks/getGoodsInfo', data)
}

// 6.5.4 商品入库
export const putGoodsIn = (data) => {
  return request.post('/rame/gsfms/stocks/putGoodsIn', data)
}

// 6.5.4 商品出库
export const putGoodsOut = (data) => {
  return request.post('/rame/gsfms/stocks/putGoodsOut', data)
}
