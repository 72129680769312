import request from './request'

// 睿美
// 获取订单列表
export const getOrderLists = (data) => {
  return request.post('/rame/gsfms/getFWOrders', data)
}
// 获取服务站城市列表
export const getcitylist = () => {
  return request.post('/rame/getCities')
}
export const getProgressList = (data) => {
  return request.post('/rame/business/getProgress', data)
}
// 删除服务订单
export const deleteFworder = (data) => {
  return request.post('/rame/gsfms/del_batch_FWOrders', data)
}
// 开始派单 rame/business/fullOrderInfo
export const fullOrderInfo = (data) => {
  return request.post('/rame/business/fullOrderInfo', data)
}
// 获取携带工具 /rame/business/getTools
export const getToolsList = () => {
  return request.get('/rame/business/getTools')
}
// 获取上门时间
export const getDtdtimes = () => {
  return request.get('/rame/getDtdtimes')
}
// 上传附加费用
export const updatePriceEstimate = (data) => {
  return request.post('/rame/business/update_price_estimate', data)
}
// 上传商品
// /rame/business/addOrderGoods
export const addOrderGoods = (data) => {
  return request.post('/rame/business/addOrderGoods', data)
}
// 获取订单详情
export const getOrderInfo = (data) => {
  return request.post('/rame/business/getOrderInfo', data)
}
// 删除订单上传的商品/rame/business/delOrderGoods
export const delOrderGoods = (data) => {
  return request.post('/rame/business/delOrderGoods', data)
}
// 上传师傅 /business/addOrderSF
export const addOrderSF = (data) => {
  return request.post('/rame/business/addOrderSF', data)
}
// 删除订单内的上门师傅 /rame/business/delOrderSF
export const delOrderSF = (data) => {
  return request.post('/rame/business/delOrderSF', data)
}
// 以前的
export const getOrderList = (data) => {
  return request.post('/jf-tech/v1/getOrders', data)
}

export const editOrder = (data) => {
  return request.post('/jf-tech/v1/updateOrder', data)
}

export const exportFile = (data) => {
  return request.get('/rame/gsfms/download_FWOrders', {
    fileName: data,
    responseType: 'blob',
    headers: { 'Content-Type': 'content-disposition' }
  })
  //  download
}

export const getExpressDetail = (data) => {
  return request.post('/jf-tech/v1/getSFRoutes', data)
}

export const sendSfOrder = (data) => {
  return request.post('/jf-tech/v1/createSFOrder', data)
}

export const SFIntercept = (data) => {
  return request.post('/jf-tech/v1/SFIntercept', data)
}

export const SfCancelOrder = (data) => {
  return request.post('/jf-tech/v1/cancelSFOrder', data)
}
